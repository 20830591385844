<template>
  <a-card :bordered="false" class="card-area">
    <label>请选择可以清理的缓存数据类型： </label>
    <a-checkbox :indeterminate="indeterminate" :checked="checkAll" @change="onCheckAllChange">
      全选
    </a-checkbox>

    <div class="cache-list">
      <a-checkbox-group v-model="checkedList" :options="cacheList" @change="onChange"/>
    </div>
    <div>
      <a-button type="danger" :loading="loading" @click="clearCacheCheckBox" icon="delete">立即清理</a-button>
    </div>

    <!--    <a-button icon="reload" style="margin-top: 8px" :loading="loading" @click="recreateInviteCode" type="danger">重置所有邀请码-->
    <!--    </a-button>-->
    <!--    -->
    <div style="display: flex;align-items: center;margin-top:20px;">
      <a-input v-model="cacheKey" placeholder="请输入缓存前缀" style="width: 400px" allow-clear></a-input>
      <a-button icon="delete" :loading="loading" @click="clearGivingKeyCache()" type="danger">清理指定Key缓存（模糊匹配，前缀不带*）
      </a-button>
    </div>

    <div v-if="isSuperadmin" style="display: flex;align-items: center;margin-top:20px;">
      <a-button icon="reload" :loading="loading" @click="reBuildAllReportData()" type="primary">重建粉丝增加报表数据
      </a-button>
    </div>
    <div v-if="isSuperadmin" style="display: flex;align-items: center;margin-top:20px;">
      <a-button icon="reload" :loading="loading" @click="convertOldTagToNewTag" type="primary">重刷老版本企业标签数据
      </a-button>
    </div>
    <div v-if="isSuperadmin" style="display: flex;align-items: center;margin-top:20px;">
      <a-button icon="reload" :loading="loading" @click="updateAllVirtualUserName" type="primary">修改麻甲用户名称为随机名称
      </a-button>
    </div>
  </a-card>
</template>

<script>
export default {
  name: "ClearCache",
  data() {
    return {
      cacheList: [{
        label: '用户登录秘钥（已登录用户需要重新登录）',
        value: 'cx:login:token:'
      }, {
        label: '用户缓存数据',
        value: 'cx:user:'
      }, {
        label: '公司缓存数据',
        value: 'cx:company:'
      }, {
        label: '所有系统缓存数据',
        value: 'cx:'
      }],
      cacheKey: '',
      checkedList: [],
      checkAll: false,
      indeterminate: true,
      loading: false
    }
  },
  computed: {
    isSuperadmin() {
      return this.$store.state.account.user.username == "superadmin";
    },
  },
  methods: {
    onChange(checkedList) {
      this.indeterminate = !!checkedList.length && checkedList.length < this.cacheList.length;
      this.checkAll = checkedList.length === this.cacheList.length;
    },
    onCheckAllChange(e) {
      Object.assign(this, {
        checkedList: e.target.checked ? this.cacheList.map(v => v.value) : [],
        indeterminate: false,
        checkAll: e.target.checked,
      });
    },
    clearGivingKeyCache() {
      if (!this.cacheKey) {
        this.$message.warning("请输入需要清理的缓存Key")
        return;
      }
      this.clearCache(this.cacheKey);
    },
    clearCacheCheckBox() {
      if (this.checkedList.length == 0) {
        this.$message.warning("请选择需要清理的缓存数据")
        return;
      }
      this.clearCache(this.checkedList.join(","));
    },
    clearCache(keys) {
      const that = this;

      this.$confirm({
        title: '确认',
        content: '注意：清理缓存可能影响其他用户的使用！！您确定要清理选择的缓存数据吗？？',
        centered: true,
        onOk() {
          that.loading = true;

          that.$post('redis/deleteKeys/', {keys}).then(() => {
            that.$message.success('缓存数据成功')
            that.loading = false
          }).catch(() => {
            that.loading = false
          })
        },
        onCancel() {
          that.loading = false
        }
      })
    },
    reBuildAllReportData() {
      const that = this;

      this.$confirm({
        title: '确认',
        content: '注意：此操作会相当耗时，并严重影响系统性能！请勿随意操作！！！您确认继续吗？',
        centered: true,
        onOk() {
          that.loading = true;

          that.$post('fansRpt/reBuildAllReportData').then(() => {
            that.$message.success('粉丝现在报表数据重建成功')
            that.loading = false
          }).catch(() => {
            that.loading = false
          })
        },
        onCancel() {
          that.loading = false
        }
      })
    },
    convertOldTagToNewTag() {
      const that = this;

      this.$confirm({
        title: '确认',
        content: '注意：此操作会相当耗时，并严重影响系统性能！请勿随意操作！！！您确认继续吗？',
        centered: true,
        onOk() {
          that.loading = true;

          that.$post('sysTag/convertOldTagToNewTag').then(() => {
            that.$message.success('老版本标签数据重建成功')
            that.loading = false
          }).catch(() => {
            that.loading = false
          })
        },
        onCancel() {
          that.loading = false
        }
      })
    },
    updateAllVirtualUserName() {
      const that = this;

      this.$confirm({
        title: '确认',
        content: '此操作将修改所有名字为【实诚人-xxx】的马甲用户为随机用户名称！！您确认继续吗？',
        centered: true,
        onOk() {
          that.loading = true;

          that.$post('import/manageImportData').then(() => {
            that.$message.success('马甲用户名称更新成功')
            that.$post('redis/deleteKeys/', {keys: 'cx:user:'});
            that.loading = false
          }).catch(() => {
            that.loading = false
          })
        },
        onCancel() {
          that.loading = false
        }
      })
    }
  }
}
</script>

<style scoped lang="less">
.cache-list {
  width: 500px;
  margin: 10px 0;
  padding: 20px;
  border: 1px solid #CCC;

  /deep/ .ant-checkbox-group {
    display: flex;
    flex-direction: column;

    .ant-checkbox-group-item {
      padding: 5px;
    }
  }
}
</style>
